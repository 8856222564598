<script setup>
import VLazyImage from 'v-lazy-image';
import { computed, defineProps } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  slug: { type: String, required: true },
  imageSrc: { type: String, required: true },
});

const link = computed(() => `/articles/${props.slug}`);
</script>

<template>
  <a :href="link" class="post-card">
    <div class="image-container">
      <VLazyImage :src="imageSrc" />
    </div>
    <div class="post-card-body">
      <h2>{{ title }}</h2>
    </div>
  </a>
</template>

<style scoped>
.post-card {
  background: #f5f5f5;
  border-radius: 0.75rem;
  color: black;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  display: block;
}

.post-card:hover {
  background: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12);
}

.image-container {
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  background: #bdbdbd;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.image-container img {
  width: 100%;
  position: absolute;
  object-fit: cover;
}

.post-card-body {
  min-height: 200px;
  padding: 1rem 1.25rem;
}

.post-card-body h2 {
  font-size: 1.1rem;
  line-height: 1.35;
}
</style>
